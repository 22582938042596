import React from 'react';
import { SearchOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, TextField, Typography, useMediaQuery } from '@mui/material';
import { CategoryCarousel, CategoryMenuCarousel } from '../../components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setToastNotification } from '../../app/slices/appSlice';

import { Container } from './cateringSearch.styles';
import { getMe, getToken } from '../../app/slices/userSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CategoryT,
  MenuForCategoryT,
  useLazyGetCategoriesCateringQuery,
  useLazyGetShowCaseCategoriesCateringQuery,
} from '../../app/api/categoryAPI';
import {
  useCreateFavoriteMutation,
  useGetFavoritesQuery,
  useRemoveFavoriteMutation,
} from '../../app/api/favoriteAPI';
import { EvaluationDataByChefId, useGetEvaluationByChefIdsMutation } from '../../app/api/chefAPI';
import ItemNotFound from '../../assets/images/ItemNotFoundImg.png';
import Cookies from 'universal-cookie';
import { theme } from '../../theme';
import GoogleAutoComplete, { LocationT } from '../../components/GoogleAutoComplete';
import { removeLocationCookie, setLocationCookie } from '../../app/slices/locationSlice';
import ModalForRegisteringEmail from '../../components/ModalForRegisteringEmail';

function CateringSearch() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumSize = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useAppSelector(getToken);
  const me = useAppSelector(getMe);
  const fromLocation = useLocation();

  const [activeCategoryId, setActiveCategoryId] = React.useState<string | undefined>('');
  const [showCaseData, setShowCaseData] = React.useState<CategoryT[] | null>(null);
  const [location, setlocation] = React.useState<{ lat: number; long: number } | undefined>();
  const [address, setAddress] = React.useState<string | undefined>();
  const [search, setSearch] = React.useState<string | undefined>();
  const [openModal, setOpenModal] = React.useState<boolean>(fromLocation.state?.openModal || false);

  const [evaluationChefsData, setEvaluationChefsData] = React.useState<EvaluationDataByChefId[]>(
    [],
  );

  const { data: favorites } = useGetFavoritesQuery(undefined, {
    skip: !token,
  });
  const [getEvaluationByChefId] = useGetEvaluationByChefIdsMutation();
  const [getShowCaseCategories, { data: showCaseApiData }] =
    useLazyGetShowCaseCategoriesCateringQuery();
  const [getCategories, { data: categories }] = useLazyGetCategoriesCateringQuery();

  const [createFavorite, { isSuccess: isSuccessCreateFavorite }] = useCreateFavoriteMutation();
  const [removeFavorite, { isSuccess: isSuccessRemoveFavorite }] = useRemoveFavoriteMutation();

  React.useEffect(() => {
    getCategories(undefined);
    getShowCaseCategories(undefined);
  }, []);

  const handleCategoryClick = (categoryId?: string | undefined, active?: boolean) => {
    if (categoryId) {
      if (active) {
        const categoryClick = categories?.filter((category) => category._id === categoryId);
        if (categoryClick) {
          setShowCaseData(categoryClick);
        }
      } else {
        showCaseDefault();
      }
    }
  };

  const handleChefClick = (chefId: string) => {
    navigate(`/chef-profile/${chefId}`);
  };

  const handleFavoriteClick = (menuId: string) => {
    if (me?.name && token) {
      if (favorites?.menus?.find((menu) => menu === menuId)) {
        removeFavorite({ menu_id: menuId });
      } else {
        createFavorite({ menu_id: menuId });
      }
    } else {
      dispatch(
        setToastNotification({
          type: 'warning',
          open: true,
          message: 'Para favoritar um menu é preciso estar logado!',
        }),
      );
      // TODO: Luiz vai fazer direto pelo router, alterar aqui após ajustar no router.
      navigate('/sign-in');
    }
  };

  const handleEvaluationDataByChefId = async () => {
    if (showCaseApiData && categories) {
      const showCaseChefdsId = showCaseApiData
        .map((showcase) => showcase.menus?.map((menu) => menu.chef_user_id._id))
        .flat();
      const categoriesChefsId = categories
        .map((category) => category.menus?.map((menu) => menu.chef_user_id._id))
        .flat();
      const set = new Set([...showCaseChefdsId, ...categoriesChefsId]);
      const array = Array.from(set).flatMap((f) => (f ? [f] : []));
      if (array?.length) {
        const evaluationData = await getEvaluationByChefId({ ids: array }).unwrap();
        setEvaluationChefsData(evaluationData);
      }
    }
  };

  const getDataEvaluationByChefId = (chefId: string) => {
    const chefSchedulingData = evaluationChefsData.find((item) => item._id === chefId);
    let ratting = '-';
    let experiences = 0;
    if (chefSchedulingData) {
      ratting =
        chefSchedulingData?.chef_id.evaluation_summary?.total_evaluation > 0 &&
        chefSchedulingData?.total_eval > 0
          ? (
              (chefSchedulingData?.chef_id.evaluation_summary.total_evaluation ?? 0) /
                (chefSchedulingData?.total_eval ?? 0) ?? 0
            ).toFixed(1)
          : '-';
      experiences = chefSchedulingData?.chef_id.evaluation_summary.total_number_of_schedulings;
    }
    return {
      ratting,
      experiences,
    };
  };

  const setMenusRecentlySeenToCookie = (menuId: string) => {
    const cookies = new Cookies();
    const menusCookie = cookies.get('abouteat_recently_seen_menus_is_catering');
    if (menusCookie) {
      const menusToSave = new Set([...menusCookie, menuId]);
      cookies.set('abouteat_recently_seen_menus_is_catering', Array.from(menusToSave), {
        path: '/explore',
        expires: new Date(Date.now() + 2592000000),
      });
    } else {
      cookies.set('abouteat_recently_seen_menus_is_catering', [menuId], {
        path: '/explore',
        expires: new Date(Date.now() + 2592000000),
      });
    }
  };

  const getMenusRecentlySeenToCookie = () => {
    const cookies = new Cookies();
    return cookies.get('abouteat_recently_seen_menus_is_catering');
  };

  const handleMenuClick = (menuId: string) => {
    setMenusRecentlySeenToCookie(menuId);
    if (location?.lat && location?.long && address) {
      setLocationCookie({
        latitude: String(location.lat),
        longitute: String(location.long),
        address,
      });
    } else {
      removeLocationCookie();
    }
    navigate(`/booking/${menuId}`);
  };

  const handleShareClick = (menuId: string) => {
    const urlToRedirect = window.location.href.replace('/catering', `/booking/${menuId}`);
    navigator.clipboard.writeText(urlToRedirect);
    dispatch(
      setToastNotification({
        type: 'success',
        open: true,
        message: 'Link do menu copiado com sucesso!',
      }),
    );
  };

  const addRecentlySeenToShowCase = (showCaseCategorys: CategoryT[], menus: MenuForCategoryT[]) => {
    const firstCategory = showCaseCategorys?.[0];
    if (showCaseCategorys.length === 1) {
      setShowCaseData([
        ...(showCaseCategorys ?? []),
        {
          title: 'Vistos Rescentemente',
          sub_title: 'Vistos rescentemente',
          icon_name: '',
          order: 2,
          is_showcase: true,
          menus,
        },
      ]);
    } else {
      setShowCaseData([
        firstCategory,
        {
          title: 'Vistos Rescentemente',
          sub_title: 'Vistos rescenemente',
          icon_name: '',
          order: 2,
          is_showcase: true,
          menus,
        },
        ...(showCaseCategorys?.slice(1) ?? []),
      ]);
    }
  };

  const showCaseDefault = () => {
    const showCaseCategories = showCaseApiData;
    if (showCaseCategories && showCaseCategories.length) {
      const menusId = getMenusRecentlySeenToCookie();
      const menus = showCaseCategories
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .reduce((acc: any, cur: any) => {
          return [...acc, ...(cur.menus ?? [])];
        }, [])
        .filter((el) => menusId?.includes(el._id))
        .filter((v, i, a) => a.findIndex((v2) => JSON.stringify(v2) === JSON.stringify(v)) === i);

      if (menus && menus.length > 0) {
        addRecentlySeenToShowCase(showCaseCategories, menus);
      } else {
        setShowCaseData(showCaseCategories);
      }
    }
  };

  React.useEffect(() => {
    showCaseDefault();
  }, [showCaseApiData]);

  React.useEffect(() => {
    if (categories && categories.length > 0 && showCaseApiData) {
      handleEvaluationDataByChefId();
    }
  }, [categories, showCaseApiData]);

  React.useEffect(() => {
    if (isSuccessRemoveFavorite) {
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Favorito removido com sucesso!',
        }),
      );
    }
  }, [isSuccessRemoveFavorite]);

  React.useEffect(() => {
    if (isSuccessCreateFavorite) {
      dispatch(
        setToastNotification({
          type: 'success',
          open: true,
          message: 'Favorito salvo com sucesso!',
        }),
      );
    }
  }, [isSuccessCreateFavorite]);

  const handleSearch = () => {
    getCategories({
      locationLat: location?.lat ? String(location?.lat) : '',
      locationLong: location?.long ? String(location?.long) : '',
      search: search || '',
    });
    getShowCaseCategories({
      locationLat: location?.lat ? String(location?.lat) : '',
      locationLong: location?.long ? String(location?.long) : '',
      search: search || '',
    });
  };

  return (
    <Container
      size={{ small: smallSize, medium: mediumSize }}
      sx={{
        padding: { xs: '100px 0 15px', md: '100px 0 5px' },
        margin: {
          xs: '10px auto 115px',
          sm: '10px auto 240px',
          md: '10px auto 200px',
          xl: '10px auto min-content',
        },
      }}
    >
      <Box
        margin={3}
        display='flex'
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems='center'
        justifyContent='center'
        gap={smallSize ? 1 : 3}
      >
        <Box sx={{ width: isMobile ? '100%' : '50%' }}>
          <GoogleAutoComplete
            label='Endereço'
            setAddress={(address?: string, location?: LocationT) => {
              setlocation(location);
              setAddress(address);
            }}
            size='small'
            margin='normal'
          />
        </Box>
        <TextField
          label='Menu ou chef'
          size='small'
          margin='normal'
          InputLabelProps={{ shrink: true }}
          id='menu-or-chef-field'
          placeholder='Menu ou chef'
          defaultValue=''
          value={search}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              handleSearch();
            }
          }}
          style={{ width: isMobile ? '100%' : '50%' }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
        />
        <IconButton
          size='large'
          onClick={handleSearch}
          sx={{ background: theme.palette.secondary.main, color: theme.palette.white.main }}
        >
          <SearchOutlined />
        </IconButton>
      </Box>
      <CategoryCarousel
        onItemClick={(itemId, active) => {
          handleCategoryClick(itemId, active);
          if (active && itemId) {
            setActiveCategoryId(itemId);
          } else {
            setActiveCategoryId(undefined);
          }
        }}
        itemActiveId={activeCategoryId}
        items={categories || []}
      />
      <Box overflow='hidden' display='flex' flexDirection='column' height='100%' mb={4}>
        {showCaseData && showCaseData?.length > 0 ? (
          showCaseData?.map((category) => {
            return (
              <Box key={category._id}>
                <Box display='flex' flexDirection='column' mt={2}>
                  <Box ml={4}>
                    <Typography color='secondary' variant='h6' fontWeight={600}>
                      {category.sub_title}
                    </Typography>
                  </Box>
                  {category.menus && (
                    <CategoryMenuCarousel
                      onChefClick={handleChefClick}
                      onFavoriteClick={handleFavoriteClick}
                      onMenuClick={handleMenuClick}
                      onShareClick={handleShareClick}
                      items={category.menus.map((menu) => {
                        const { experiences, ratting } = getDataEvaluationByChefId(
                          menu.chef_user_id._id,
                        );
                        return {
                          menuId: menu._id || '',
                          menuImage: menu.picture_url,
                          menuName: menu.name,
                          menuPrice: undefined,
                          chefId: menu.chef_user_id._id,
                          chefName: menu.chef_user_id.name,
                          chefImage: menu.chef_user_id.chef_id.picture_url,
                          chefExperiences: experiences ?? 0,
                          chefRating: String(ratting ?? '-'),
                          isFavorite: !!favorites?.menus?.find((el) => {
                            return el === menu._id;
                          }),
                        };
                      })}
                    />
                  )}
                </Box>
              </Box>
            );
          })
        ) : (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexDirection='column'
            gap={3}
          >
            <img
              src={ItemNotFound}
              alt={'Nenhuma categoria encontrada'}
              style={{ height: smallSize ? 350 : 400 }}
            ></img>
            <Typography color='primary' variant='h5' fontWeight={600}>
              Não existe menus cadastrados com esse filtro
            </Typography>
            <Button variant='contained' href='#contained-buttons'>
              Ver todos
            </Button>
          </Box>
        )}
      </Box>
      <ModalForRegisteringEmail
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </Container>
  );
}

export default CateringSearch;
