import { apiBase } from '../../service';

interface SlotI {
  start?: string;
  end?: string;
}

export type SlotConfigT = {
  day: number;
  slots?: SlotI[] | [];
};

export type AvailabilityConfigT = {
  days: SlotConfigT[];
};

export type ScheduleT = {
  _id?: string;
  days?: SlotConfigT[];
};

export type SchedulePostT = {
  days: AvailabilityConfigT;
};

export type AvailableScheduleT = {
  start: string;
  end: string;
  available: boolean;
};

export type AvailableScheduleParamsT = {
  chefId: string;
  date: string;
  menuId: string;
};

export const ScheduleService = apiBase.injectEndpoints({
  endpoints: (builder) => ({
    createSchedule: builder.mutation<ScheduleT, SchedulePostT>({
      query: ({ days }) => ({
        url: '/schedule',
        method: 'POST',
        body: { days },
      }),
      invalidatesTags: ['Schedule'],
    }),
    getSchedule: builder.query<ScheduleT, string>({
      query: (chefId) => ({
        url: `/schedule/chef/${chefId}`,
      }),
      providesTags: ['Schedule'],
    }),
    getAvailableSchedule: builder.query<AvailableScheduleT[], AvailableScheduleParamsT>({
      query: ({ chefId, date, menuId }) => ({
        url: `/schedule/chef/${chefId}/available?date=${date}&menu_id=${menuId}`,
      }),
      providesTags: ['Schedule'],
    }),
  }),
});

export const {
  useCreateScheduleMutation,
  useGetScheduleQuery,
  useLazyGetAvailableScheduleQuery,
  useLazyGetScheduleQuery,
} = ScheduleService;
